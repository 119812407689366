import {
  capitalize,
  Divider,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
} from '@mui/material'
import {
  Check,
  Comment,
  Copy as CopyIcon,
  Delete as DeleteIcon,
  Link as LinkIcon,
  Settings as SettingsIcon,
} from '@tunasong/icons'
import {
  EditorQueries,
  getActionsComponent,
  getActiveView,
  getContentViews,
  runEditorCommand,
  useEditor,
  useGlobalAppContext,
} from '@tunasong/plugin-lib'
import { isEntity, isPersisted, type CoreElement } from '@tunasong/schemas'
import { Hotkey, makeLabel, makeStyles, shortcuts, useCmdHotkey, useNavigateToEntity } from '@tunasong/ui-lib'
import { useCallback, useMemo, type FC, type MouseEvent } from 'react'
import { ReactEditor } from 'slate-react'
import { EditorPopup } from '../editor/editor-popup.js'

export interface BlockMenuProps {
  block: CoreElement
  open: boolean
  anchorEl: HTMLElement | null
  onShowProperties?(): void
  onComments?(): void
  onClose?(): void
}

const useStyles = makeStyles()(() => ({
  menu: {
    width: 275,
  },
  popup: {
    zIndex: 1,
  },
}))

/** A Block. Extends Box, so all Box */
export const BlockMenu: FC<BlockMenuProps> = props => {
  /** @todo Factor this out into a useHotKey(cmd) */
  const blockDeleteCmd = useCmdHotkey('Block: Delete')
  const blockDuplicateCmd = useCmdHotkey('Block: Duplicate')
  const blockPropertiesCmd = useCmdHotkey('Block: Properties')

  const { block, onComments, onShowProperties, open, anchorEl, onClose } = props
  const { classes } = useStyles()
  const navigateToEntity = useNavigateToEntity()
  const editor = useEditor()
  const plugins = editor.pluginList
  const globalContext = useGlobalAppContext()

  const path = ReactEditor.findPath(editor as never, block)

  const handleClose = useCallback(() => onClose && onClose(), [onClose])
  const openAndClose = useCallback(
    (setProp: (prop: boolean) => void) => () => {
      setProp(true)
      handleClose()
    },
    [handleClose]
  )

  const handleDeleteBlock = useCallback(() => {
    runEditorCommand({
      commandId: 'block-delete',
      globalContext,
      editor,
      plugins,
      nodeEntry: [block, path],
    })
    handleClose()
  }, [block, editor, globalContext, handleClose, path, plugins])

  const handleDuplicateBlock = useCallback(() => {
    runEditorCommand({
      commandId: 'block-duplicate',
      globalContext,
      editor,
      plugins,
      nodeEntry: [block, path],
    })
    handleClose()
  }, [block, editor, globalContext, handleClose, path, plugins])

  const handleOpen = useCallback(
    (ev: MouseEvent) => {
      if (!(isPersisted(block) && isEntity(block))) {
        return
      }
      ev.preventDefault()

      handleClose()

      navigateToEntity(block)
    },
    [block, handleClose, navigateToEntity]
  )

  const PluginMenuItems = useMemo(() => getActionsComponent(plugins), [plugins])

  /** @todo getting deep recursion issues here */
  const views = useMemo(() => getContentViews(plugins as never, block.type as never), [block.type, plugins])
  const currentView = useMemo(() => getActiveView(block), [block])

  const handleSetView = useCallback(
    (view: string) => () => {
      EditorQueries.updateElement(editor, block, { options: { ...block.options, activeView: view } })
    },
    [block, editor]
  )

  return (
    <EditorPopup
      disablePortal={false}
      className={classes.popup}
      open={open}
      editor={editor}
      anchorEl={anchorEl}
      onClose={handleClose}
    >
      <MenuList className={classes.menu} dense={true} autoFocusItem={false}>
        <MenuItem autoFocus={true} dense={true} disabled={true}>
          <ListItemIcon>
            <ListItemText>{capitalize(block.type)}</ListItemText>
          </ListItemIcon>
        </MenuItem>

        {Object.keys(views).length > 0
          ? [
              <Divider key={1} />,
              <MenuItem key={2} title="Views" dense={true} disabled={true}>
                <ListItemText>Views</ListItemText>
              </MenuItem>,

              ...Object.keys(views).map(view => (
                <MenuItem key={view} title={view} disabled={currentView === view} onClick={handleSetView(view)}>
                  {currentView === view ? (
                    <ListItemIcon>
                      <Check />
                    </ListItemIcon>
                  ) : null}
                  <ListItemText inset={currentView !== view}>{capitalize(view)}</ListItemText>
                </MenuItem>
              )),
            ]
          : null}

        <PluginMenuItems element={block} onClose={handleClose} dense={true} />

        {isPersisted(block) &&
          isEntity(block) && [
            <Divider key={0} />,
            <MenuItem key={1} autoFocus={true} dense={true} onClick={handleOpen}>
              <ListItemIcon>
                <LinkIcon />
              </ListItemIcon>
              <ListItemText>Open as Page</ListItemText>
            </MenuItem>,
          ]}
        <Divider />
        <MenuItem autoFocus={true} dense={true} onClick={handleDuplicateBlock}>
          <ListItemIcon>
            <CopyIcon />
          </ListItemIcon>
          <ListItemText>Duplicate</ListItemText>
          {blockDuplicateCmd ? (
            <ListItemSecondaryAction>
              <Hotkey elevation={0}>{makeLabel()}</Hotkey>
            </ListItemSecondaryAction>
          ) : null}
        </MenuItem>

        <MenuItem dense={true} onClick={onShowProperties && openAndClose(onShowProperties)}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText>Properties</ListItemText>
          {blockPropertiesCmd ? (
            <ListItemSecondaryAction>
              <Hotkey elevation={0}>{makeLabel(blockPropertiesCmd.hotkey)}</Hotkey>
            </ListItemSecondaryAction>
          ) : null}
        </MenuItem>
        <MenuItem dense={true} onClick={onComments && openAndClose(onComments)}>
          <ListItemIcon>
            <Comment />
          </ListItemIcon>
          <ListItemText>Comments</ListItemText>
          <ListItemSecondaryAction>
            <Hotkey elevation={0}>{makeLabel(shortcuts.Comments.hotkey)}</Hotkey>
          </ListItemSecondaryAction>
        </MenuItem>
        <MenuItem dense={true} onClick={handleDeleteBlock}>
          <ListItemIcon>
            <DeleteIcon color="error" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
          {blockDeleteCmd ? (
            <ListItemSecondaryAction>
              <Hotkey elevation={0}>{makeLabel(blockDeleteCmd.hotkey)}</Hotkey>
            </ListItemSecondaryAction>
          ) : null}
        </MenuItem>
      </MenuList>
      <Typography sx={{ marginRight: 2, textAlign: 'right', display: 'block' }} variant="caption">
        {block.id}
      </Typography>
    </EditorPopup>
  )
}
