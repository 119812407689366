import { type StandardTextFieldProps, TextField } from '@mui/material'
import { logger, type Meter, meterToString, parseMeter } from '@tunasong/models'
import { rhythmSchema } from '@tunasong/schemas'
import React, { type ChangeEvent, type FC, useCallback, useEffect, useState } from 'react'

export interface MeterSelectProps extends Omit<StandardTextFieldProps, 'onChange' | 'value'> {
  className?: string
  meter?: Meter
  onChange(tempo: Meter): void
}

export const MeterSelect: FC<MeterSelectProps> = ({ meter, onChange, ...restProps }) => {
  const [value, setValue] = useState('')
  const [isValid, setIsValid] = useState(true)

  useEffect(() => {
    if (!meter) {
      return
    }
    const s = meterToString(meter)
    if (s) {
      setValue(s)
    }
  }, [meter])

  const handleSetMeter = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      const newVal = ev.target.value
      if (newVal === value) {
        return
      }
      setValue(newVal)
      const m = parseMeter(newVal)

      const parseResult = rhythmSchema.pick({ meter: true }).safeParse({ meter: m })
      const valid = parseResult.success

      setIsValid(valid)

      if (m && valid) {
        onChange(m)
      }
    },
    [onChange, value]
  )

  return <TextField error={!isValid} value={value} onChange={handleSetMeter} label="Meter" {...restProps} />
}

export default MeterSelect
