import { type CoreElement } from '@tunasong/schemas'
import { type MusicContextType } from '@tunasong/music-lib'
import { type TunaEditor } from '@tunasong/plugin-lib'

export const buildMusicContext = (
  editor: TunaEditor,
  rootElement: CoreElement,
  outerContext?: Partial<MusicContextType> | null
): MusicContextType => {
  /** Plugins can build their own context  */
  let pluginContext: Partial<MusicContextType> = {}
  const { pluginList: plugins } = editor
  for (const plugin of plugins ?? []) {
    const pContext = plugin.getContext ? plugin.getContext(editor, rootElement) : {}
    pluginContext = {
      ...pluginContext,
      ...pContext,
    }
  }
  /** Return the full context. Plugins cannot overwrite the base */
  return {
    ...outerContext,
    ...pluginContext,
  } as MusicContextType
}
