import { deepEqual } from '@tunasong/models'
import { type SuggestionContext } from '@tunasong/models'
import { type MusicContextType } from '@tunasong/music-lib'
import { type TunaEditor, useEditorMessage } from '@tunasong/plugin-lib'
import { features, useMusicContext, useSelector, useStore, useThunkDispatch } from '@tunasong/redux'
import { useDelayedInvoke } from '@tunasong/ui-lib'
import { useEffect, useMemo, useRef, useState } from 'react'
import { getSuggestions } from './suggestions.js'

export const useSuggestions = (
  editor?: TunaEditor | null,
  { delay = 500, types }: { delay?: number; types?: string[] } = {}
) => {
  const [context = null] = useMusicContext({ editorId: editor?.id, selector: state => state })
  const loading = useSelector(state => state.suggestions.isLoading)
  const suggestions = useSelector(state => state.suggestions.suggestions)
  const { getState } = useStore()
  const dispatch = useThunkDispatch()

  const showContexts: SuggestionContext[] = useMemo(() => ['substitute', 'next'], [])

  /** Logic for when to show the suggestions */
  const [show, setShow] = useState(false)
  useEditorMessage({
    editor,
    type: 'hide-suggestions',
    handler: () => setShow(false),
  })
  useEditorMessage({
    editor,
    type: 'show-suggestions',
    handler: () => setShow(true),
  })

  const delayedInvoke = useDelayedInvoke(delay)

  /** Update suggestions when the selection has changed */
  const prevContext = useRef<MusicContextType | null>(null)
  useEffect(() => {
    /** Don't update suggestions if the context hasn't changed */
    if (!editor || (prevContext.current && deepEqual(context, prevContext.current))) {
      return
    }

    const { cancelInvoke } = delayedInvoke(() => {
      if (getState().suggestions.suggestions.length > 0) {
        /** Clear current suggestions as they are not relevant anymore */
        dispatch(features.suggestions.actions.clearSuggestions())
      }
      prevContext.current = context ?? null
      if (getState().suggestions.isLoading) {
        return
      }
      dispatch(features.suggestions.actions.isLoading(true))
      getSuggestions({ context, editor, plugins: editor.pluginList, showContexts }).then(s => {
        dispatch(features.suggestions.actions.suggestions(s))
      })
    })
    /** Cancel the invoke if the context changes */
    return () => cancelInvoke()
  }, [context, delayedInvoke, dispatch, editor, getState, showContexts])

  const filteredSuggestions = useMemo(
    () => (types ? suggestions.filter(s => types.includes(s.type)) : suggestions),
    [suggestions, types]
  )

  return {
    show,
    loading: loading || Boolean(show && suggestions === null),
    suggestions: filteredSuggestions,
  }
}
