import { Typography } from '@mui/material'
import { AutoForm } from '@tunasong/autoform'
import { isOwned, logger } from '@tunasong/models'
import { type Template, type TunaPlugin, type TunaPluginCreateEntityChangeProps } from '@tunasong/plugin-lib'
import { type Entity } from '@tunasong/schemas'
import { ACLList } from '@tunasong/ui-lib'
import { useCallback, useEffect, type FC } from 'react'
import { canConfigureEntity } from './util.js'

export interface ConfigureEntityProps {
  plugin: TunaPlugin
  template?: Template
  onConfigured: (props: { entity: Entity; complete?: boolean }) => void
  onError: () => void
}

export const ConfigureEntity: FC<ConfigureEntityProps> = props => {
  const { plugin, template, onConfigured, onError } = props

  const handleConfigureEntity = useCallback(
    ({ entity, valid, complete }: TunaPluginCreateEntityChangeProps) => {
      if (entity && valid) {
        onConfigured({ entity, complete })
      }
    },
    [onConfigured]
  )
  const handleSubmit = useCallback(
    (e: Entity) => handleConfigureEntity({ entity: e, valid: true }),
    [handleConfigureEntity]
  )
  const clearEntity = useCallback(() => {
    handleConfigureEntity({ entity: undefined, valid: false })
    onError()
  }, [handleConfigureEntity, onError])

  useEffect(() => {
    if (!canConfigureEntity(plugin) && template?.template) {
      handleConfigureEntity({ entity: template.template, valid: true })
    }
  }, [handleConfigureEntity, plugin, template?.template])

  if (!plugin.node?.type) {
    logger.warn('No type for plugin', plugin)
    return null
  }

  return (
    <>
      {plugin.schema ? (
        <AutoForm
          defaultValues={template?.template ?? {}}
          schema={plugin.schema}
          onSubmit={handleSubmit}
          onError={clearEntity}
        />
      ) : null}
      {plugin.components?.CreateEntity ? <plugin.components.CreateEntity onChange={handleConfigureEntity} /> : null}

      {isOwned(template?.template) ? (
        <>
          <Typography variant="h4">Access Control</Typography>
          <Typography color="text.secondary">
            You are granting the following people access. These permissions are inherited from the template.
          </Typography>
          <ACLList entity={template.template} />
        </>
      ) : null}
    </>
  )
}

export default ConfigureEntity
