import Close from '@mui/icons-material/Close'
import { Box, IconButton } from '@mui/material'
import { type Instrument } from '@tunasong/schemas'
import { Dialog, useCommonStyles } from '@tunasong/ui-lib'
import classNames from 'classnames'
import { useCallback, useState, type FC } from 'react'
import { ButtonLink } from '@tunasong/ui-lib'
import InstrumentEditor from './instrument-editor.js'

export interface InstrumentViewProps {
  className?: string
  instrument: Instrument
  showEffects?: boolean
  showDetails?: boolean
  onChange?(instrument: Instrument): void
  onRemove?(): void
}

const InstrumentView: FC<InstrumentViewProps> = props => {
  const { className, instrument, showDetails: showDetailsOnMount = false, onChange, onRemove } = props
  const [showDetails, setShowDetails] = useState(showDetailsOnMount)
  const { classes: cc } = useCommonStyles()

  const handleDetails = useCallback(() => {
    setShowDetails(true)
  }, [])

  const handleCloseDetails = useCallback(() => {
    setShowDetails(false)
  }, [])

  const label = instrument.name ? instrument.name : instrument.type

  return (
    <>
      <Box display="flex" flexDirection="column" className={className}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box flexGrow={1} className={classNames(cc.controlTextRegular, cc.controlLink)}>
            <ButtonLink onClick={handleDetails}>{label}</ButtonLink>
          </Box>

          {onRemove && (
            <IconButton className={cc.controlButton} onClick={onRemove}>
              <Close />
            </IconButton>
          )}
        </Box>
      </Box>
      <Dialog open={showDetails} title={`${instrument.type} details`} onClose={handleCloseDetails}>
        <InstrumentEditor instrument={instrument} onChange={onChange} />
      </Dialog>
    </>
  )
}

export default InstrumentView
