/** Chat Icon with badge */
import { Badge } from '@mui/material'
import { Chat, type IconProps } from '@tunasong/icons'
import { type FC } from 'react'

interface ChatBadgeProps extends IconProps {
  showBadge?: boolean
  numComments: number
}
export const ChatBadge: FC<ChatBadgeProps> = ({ showBadge = true, numComments = 0, ...restProps }) =>
  showBadge ? (
    <Badge color="secondary" showZero={false} badgeContent={numComments}>
      <Chat {...restProps} />
    </Badge>
  ) : (
    <Chat {...restProps} />
  )
