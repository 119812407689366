import { useDndBlock, useDropLine } from '@udecode/plate-dnd'
import { type FC, useRef } from 'react'
import { CoreBlock, type CoreBlockProps } from './core-block.js'

export const DndBlock: FC<CoreBlockProps> = props => {
  const nodeRef = useRef<HTMLDivElement>(null)

  const {
    dragRef,
    isDragging,
    isOver: isDragOver,
  } = useDndBlock({
    id: props.element.id ?? 'unknown',
    nodeRef,
  })
  const { dropLine } = useDropLine()

  return (
    <CoreBlock
      {...props}
      dropLine={dropLine}
      dragRef={dragRef}
      // we use the browser drag over to show the drop line
      // blockRef={nodeRef}
      isDragOver={isDragOver && isDragging}
    />
  )
}
