import { isTaggedSysEntity } from '@tunasong/models'
import type { Entity, Persisted } from '@tunasong/schemas'
import { useSearch } from './search.hook.js'

export interface UseRecentProps {
  /** If specified, get only decendants of parentId */
  parentId?: string
  /** Entities to exclude from the list, e.g., if they are already shown */
  exclude?: Persisted<Entity>[]
  /** The maximum number of entities to return */
  maxCount?: number
}

export const useRecent = ({ parentId, exclude, maxCount = Number.MAX_SAFE_INTEGER }: UseRecentProps) => {
  const {
    results,
    searching: isLoading,
    isSuccess,
  } = useSearch({
    queryType: 'recent',
    query: parentId,
    size: maxCount,
  })

  const searchResults = results
    .filter(e => !isTaggedSysEntity(e))
    .filter(e => !Boolean(e.trash))
    .filter(e => !exclude?.some(ex => ex.id === e.id))
    .slice(0, maxCount)

  return { searchResults, isLoading, isSuccess }
}
