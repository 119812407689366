import { isEntityRef, isValidSlateElement } from '@tunasong/models'
import { Editor, Transforms, type TDescendant, type TunaEditor } from '@tunasong/plugin-lib'
import { isText, isTopBlockElement } from '@tunasong/schemas'

export const withOverrides = <T extends TunaEditor>({ editor }: { editor: T }) => {
  const { normalizeNode, insertNode } = editor

  // normalize node to allow insert without children
  editor.insertNode = (node: TDescendant) => {
    if (!isText(node) && !isValidSlateElement(node)) {
      insertNode({ ...node, children: [{ text: '' }] })
      return
    }
    insertNode(node)
  }
  editor.normalizeNode = entry => {
    const [node, path] = entry

    /** Ensure that top block elements are not nested */
    const isTopLevel = path.length === 1
    const parent = path.length === 0 ? entry : Editor.parent(editor, path)

    if (
      isTopBlockElement(node) &&
      !editor.isInline(node) &&
      !isTopLevel &&
      editor.isVoid(node) &&
      !isEntityRef(parent?.[0]) &&
      !isEntityRef(node)
    ) {
      /** Move the node top top-level, after this block */
      Transforms.moveNodes(editor, { at: path, to: [path[0] + 1] })
      return
    }

    // Fall back to the original `normalizeNode` to enforce other constraints.
    normalizeNode(entry)
  }

  return editor
}

export default withOverrides
