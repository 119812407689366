import { Divider, Grid2, type Grid2Props } from '@mui/material'
import type { Template, TunaPlugin } from '@tunasong/plugin-lib'
import { type Entity } from '@tunasong/schemas'
import { useTemplates } from '@tunasong/search'
import { useCallback, type FC } from 'react'
import TemplateCard from './template-card.js'

interface TemplateSearchProps extends Omit<Grid2Props, 'onSelect'> {
  plugins: TunaPlugin[]
  selected?: Template
  onSelect: (template: Template) => void
}

const mapToTemplate = (entity: Entity): Template => ({
  name: entity.name ?? 'Untitled',
  description: entity.description,
  template: {
    ...entity,
    id: undefined,
  },
})

export const TemplateSearch: FC<TemplateSearchProps> = props => {
  const { selected, onSelect, plugins, ...restProps } = props

  /** Get all the templates available to the user */
  const { results: templates } = useTemplates(selected?.template.type)

  const handleSelect = useCallback(
    (template: Template) => () => {
      onSelect(template)
    },
    [onSelect]
  )

  const mappedTemplates = templates
    .map((templateEntity, idx) => {
      const template = mapToTemplate(templateEntity)
      const plugin = plugins.find(p => p.node?.type === template.template.type)
      /** when we switch categories, the plugins will change, and we may not have relevant plugins  */
      return plugin ? (
        <Grid2 size={{ xs: 12, sm: 6, md: 4 }} key={idx}>
          <TemplateCard
            key={templateEntity.id}
            title={template.name}
            description={template.description ?? ''}
            plugin={plugin}
            template={template}
            onSelect={handleSelect(template)}
            selected={selected?.name === templateEntity.name}
          />
        </Grid2>
      ) : null
    })
    .filter(Boolean)

  return mappedTemplates.length > 0 ? (
    <>
      <Divider sx={{ my: 2 }}>Templates</Divider>
      <Grid2 container spacing={2} {...restProps}>
        {mappedTemplates}
      </Grid2>
    </>
  ) : null
}

export default TemplateSearch
