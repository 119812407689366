import { List } from '@mui/material'
import { useEffect, useState, type FC } from 'react'
import { useAudioEngine } from '../hooks/audio-engine.js'
import MidiDeviceItem from './midi-device-item.js'

export interface MidiDeviceListProps {}

export const MidiDeviceList: FC<MidiDeviceListProps> = props => {
  const {} = props
  const { midi } = useAudioEngine()

  /** @todo do this properly, e.g., emitting events from Midi */
  const [devices, setDevices] = useState<BluetoothDevice[]>([])
  useEffect(() => {
    const interval = setInterval(() => {
      setDevices(midi?.btMidi?.btDevices ?? [])
    }, 1000)
    return () => clearInterval(interval)
  }, [midi?.btMidi?.btDevices])

  const handleForget = async (device: BluetoothDevice) => {
    setDevices(devices => devices.filter(d => d.id !== device.id))
    midi?.btMidi?.forgetDevice(device)
  }

  return (
    <List>
      {devices.map(device => (
        <MidiDeviceItem key={device.id} device={device} onForgetDevice={handleForget} />
      ))}
    </List>
  )
}

export default MidiDeviceList
