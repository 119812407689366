import {
  Button,
  capitalize,
  DialogActions,
  DialogContent,
  LinearProgress,
  Step,
  StepButton,
  Stepper,
  Typography,
} from '@mui/material'
import { type Template, type TunaPlugin } from '@tunasong/plugin-lib'
import { entityTypeNames, type Entity, type EntityType, type Persisted } from '@tunasong/schemas'
import { Dialog, useDimensions, useEntityStyles, useNavigateToEntity } from '@tunasong/ui-lib'
import { useCallback, useMemo, useState, type FC, type MouseEvent } from 'react'
import invariant from 'tiny-invariant'
import ConfigureEntity from './configure-entity.js'
import CreateEntityGrid from './create-entity-grid.js'
import { useCreateEntity } from './create-entity.hook.js'
import { canConfigureEntity } from './util.js'

export interface CreateEntityDialogProps {
  plugins: TunaPlugin[]
  parent?: Persisted<Entity>
  open: boolean
  onClose: (props?: { restoreLayout?: boolean }) => void
}

const steps = ['Select type', 'Configure']

export const CreateEntityDialog: FC<CreateEntityDialogProps> = props => {
  const { plugins = [], parent, open, onClose } = props
  const { classes } = useEntityStyles()
  const navigateToEntity = useNavigateToEntity()

  const [newEntity, setNewEntity] = useState<Entity>()

  const [activeStep, setActiveStep] = useState(0)
  const [completed] = useState<Record<number, boolean>>({})
  const handleStep = useCallback((idx: number) => () => setActiveStep(idx), [])

  const handleNext = useCallback(() => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }, [])
  const handlePrev = useCallback(() => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }, [])

  const [activeTemplate, setActiveTemplate] = useState<Template>()

  const [loading, setLoading] = useState(false)
  const createEntity = useCreateEntity({ navigate: true, parent })

  const handleCreate = useCallback(
    async (newEntity: Entity) => {
      invariant(newEntity, 'newEntity is undefined')
      setLoading(true)
      /** createEntity will navigate to the new entity */
      const onCreate = activeTemplate?.onCreate
      const e = await createEntity({ entity: newEntity, onCreate })
      setLoading(false)
      setActiveStep(0)
      setNewEntity(undefined)
      /** Since we navigate to the new entity, we'll skip closing the dialog (which will result in a race for view param) */
      navigateToEntity(e)
      // We want to close the dialog, but not restore the view since we've created a new entity
      onClose({ restoreLayout: false })
    },
    [activeTemplate?.onCreate, createEntity, navigateToEntity, onClose]
  )

  const allowedEntities: readonly EntityType[] = useMemo(() => {
    const parentPlugin = plugins.find(p => p.node?.type === parent?.type)
    if (!parentPlugin?.allowChildrenTypes || parentPlugin?.allowChildrenTypes === true) {
      return entityTypeNames
    }
    return parentPlugin.allowChildrenTypes
  }, [parent?.type, plugins])

  const handleConfigured = useCallback(
    ({ entity, complete }: { entity: Entity; complete?: boolean }) => {
      setNewEntity({ ...entity, parentId: parent?.id })
      if (complete) {
        handleCreate(entity)
      }
    },
    [handleCreate, parent?.id]
  )
  const handleError = useCallback(() => setNewEntity(undefined), [])

  const handleComplete = useCallback(() => {
    invariant(newEntity, 'newEntity must be defined')
    handleCreate(newEntity)
  }, [handleCreate, newEntity])

  const activePlugin = useMemo(
    () => plugins.find(p => p.node?.type === activeTemplate?.template.type),
    [activeTemplate?.template.type, plugins]
  )

  const hasNext = activeStep < steps.length - 1 && canConfigureEntity(activePlugin)
  const hasPrev = activeStep > 0

  const handleSelect = useCallback(
    (template: Template | null) => {
      setActiveTemplate(template ?? undefined)
      // If there is no configuration step, we can skip to the end
      if (!canConfigureEntity(activePlugin)) {
        setNewEntity(template?.template)
      }
    },
    [activePlugin]
  )

  const { isSmallOrSmaller } = useDimensions()
  const fullScreen = isSmallOrSmaller
  const handleClose = (ev: MouseEvent) => {
    ev.preventDefault()
    ev.stopPropagation()
    onClose()
  }

  return (
    <Dialog
      classes={{ paper: classes.createNew }}
      title={`New ${capitalize(newEntity?.type ?? 'Entity')}`}
      open={open}
      PaperProps={{
        sx: {
          minHeight: '80vh',
        },
      }}
      maxWidth={fullScreen ? undefined : 'md'}
      fullWidth={true}
      fullScreen={fullScreen}
      showClose={false}
      onClose={handleClose}
    >
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>

      {loading || !parent ? (
        <LinearProgress sx={{ mt: 4 }} />
      ) : (
        <>
          <DialogContent>
            <Typography variant="caption">
              You can create the following page types. Note that some types may not be available as children of a{' '}
              {capitalize(parent.type)}.
            </Typography>
            {activeStep === 0 ? (
              <CreateEntityGrid
                plugins={plugins}
                onSelect={handleSelect}
                selected={activeTemplate}
                // showCategories={allowedEntities.length > 5}
                showCategories={true}
                allowedChildEntities={allowedEntities}
              />
            ) : null}
            {activeStep === 1 && activePlugin ? (
              <ConfigureEntity
                plugin={activePlugin}
                template={activeTemplate}
                onConfigured={handleConfigured}
                onError={handleError}
              />
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button disabled={!hasPrev} onClick={handlePrev} sx={{ mr: 1 }}>
              Prev
            </Button>

            {hasNext ? (
              <Button disabled={!hasNext || !activeTemplate} onClick={handleNext} sx={{ mr: 1 }}>
                Next
              </Button>
            ) : null}
            {!hasNext ? (
              <Button disabled={!newEntity} sx={{ mr: 1 }} onClick={handleComplete}>
                Complete
              </Button>
            ) : null}
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default CreateEntityDialog
