import { defaultEditorValue } from '@tunasong/models'
import {
  getYDocString,
  usePlugins,
  type OnCreateHandler,
  type PluginCategory,
  type TunaPlugin,
  useGlobalAppContext,
} from '@tunasong/plugin-lib'
import { entitiesApi } from '@tunasong/redux'
import { isCoreElement, type Entity, type EntityType, type Persisted } from '@tunasong/schemas'
import { useNavigateToEntity } from '@tunasong/ui-lib'
import { useCallback } from 'react'
import invariant from 'tiny-invariant'

interface SortedPlugins {
  plugins: TunaPlugin[]
  allowedTypes: readonly EntityType[]
  category?: PluginCategory
}

/** @todo the plugin should specify whether they are top-level or not */
export const getCreateEntityPlugins = ({ plugins, category, allowedTypes }: SortedPlugins) =>
  plugins
    ?.filter(p => p.node?.type && allowedTypes.includes(p.node?.type))
    /** We require templates in order to create entities */
    .filter(p => (category ? (p.category ?? 'other') === category : true))
    .filter(p => Boolean(Object.keys(p.templates ?? {}).length > 0))
    // .filter(p => p.icon && p.type && isTopLevelEntityType(p.type))
    .sort((a, b) => {
      if (a.node?.type && b.node?.type && a.node?.type > b.node?.type) {
        return 1
      }
      return -1
    }) as TunaPlugin[]

interface CreateEntity {
  parent?: Persisted<Entity>
  /** Navigate to created entity. @default true */
  navigate?: boolean
}

export const useCreateEntity = <T extends Entity = Entity>({ navigate = true, parent }: CreateEntity) => {
  const plugins = usePlugins('all')
  const navigateToEntity = useNavigateToEntity()
  const [createEntity] = entitiesApi.useCreateEntityMutation()
  const globalContext = useGlobalAppContext()

  const create = useCallback(
    async ({ entity, onCreate }: { entity: T; onCreate?: OnCreateHandler<T> }) => {
      const plugin = plugins?.find(p => p.node?.type === entity.type)

      invariant(entity.type, 'Entity type is required')
      invariant(plugin, `Plugin for type ${entity.type} not found`)

      const { collaborative = false } = plugin
      const yDoc = collaborative
        ? getYDocString(isCoreElement(entity) ? entity.children : defaultEditorValue)
        : undefined

      const data: Entity = { yDoc, ...entity, parentId: parent?.id }

      const e = await createEntity({ entity: data, parent: parent ?? null }).unwrap()

      if (onCreate) {
        await onCreate({ entity: e as Persisted<T>, parent, globalContext })
      }

      if (navigate) {
        /** If the type has an Edit view, navigate to that */
        const layouts = Object.keys(plugin.layouts ?? {})
        const layout = layouts.find(v => v === 'Edit') ? 'Edit' : undefined
        navigateToEntity(e, { layout })
      }
      return e as Persisted<T>
    },
    [createEntity, globalContext, navigate, navigateToEntity, parent, plugins]
  )

  return create
}
