import {
  Box,
  CircularProgress,
  Divider,
  Fab,
  LinearProgress,
  Paper,
  Typography,
  Zoom,
  type BoxProps,
} from '@mui/material'
import { type VirtualElement } from '@popperjs/core'
import { Play, Stop } from '@tunasong/icons'
import { EventFilters, type AudioPosition, type ClipRegion } from '@tunasong/models'
import {
  isAudio,
  isPersisted,
  type AudioEvent,
  type Audio as AudioMedia,
  type Entity,
  type VideoMedia,
} from '@tunasong/schemas'
import { Popup, useContainerSize, useDelayedInvoke, useDimensions, useHotkey } from '@tunasong/ui-lib'
import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo, useRef, useState, type FC } from 'react'
import invariant from 'tiny-invariant'
import {
  useAudioPlayer,
  useAudioTransportControl,
  usePeaks,
  useTimelineInteraction,
  useTransport,
} from '../hooks/index.js'
import { trackMouse } from '../lib/track-mouse.js'
import { Timeline } from '../timeline/index.js'
import { CommentStream } from './comment-stream.js'
import { Event } from './event.js'
import WaveCanvas from './wave-canvas.js'
import { useStyles } from './waveform.styles.js'

export interface WaveformProps extends BoxProps {
  className?: string
  // mixer: Mixer | null
  // mediaEl?: HTMLMediaElement | null
  parent?: Entity

  /** Use the Waveform from this media */
  media?: AudioMedia | VideoMedia

  /** Use the  Waveform from this URL */
  url?: string

  /** Automatically stop when another clip is played. @default false */
  autoStop?: boolean

  /** The active event to display at the current position */
  activeEvent?: AudioEvent

  /** ShowEvents */
  showEvents?: boolean | 'hover'

  /** show comments stream. @default false */
  showCommentStream?: boolean

  /** All the events. Set to null if there are no events */
  events?: AudioEvent[]

  regions?: ClipRegion[]

  /** If timeline is 'time', events are omitted from timeline */
  timeline?: 'time' | boolean

  badge?: string | null

  /** Render the peaks from the source audio if pre-rendered peaks does not exist */
  renderPeaks?: boolean

  /** Keyboard controls - e.g., play on Space */
  keyboardControls?: boolean

  onPlay?(): void
  onPause?(): void
  // recorder?: Recorder
  // /** Show region controls */
  // showRegion?: boolean
  // /** Show rate controls */
  // showRateControls?: boolean
  // /** Show the timeline */

  // onUpdateRegions?(regions: ClipRegion[])
  // onPlaying?(pos: AudioPosition)
  // onRecording?(media: AudioMedia, recording: Blob)
}

export const Waveform: FC<WaveformProps> = props => {
  const {
    className,
    media,
    url,
    autoStop = true,
    activeEvent,
    events,
    showEvents = true,
    showCommentStream = false,
    timeline = true,
    keyboardControls = false,
    badge = media?.name,
    renderPeaks = false,
    onPlay,
    onPause,
    ...restProps
  } = props

  /** Ref to the top-level container */
  const ref = useRef<HTMLDivElement | null>(null)

  const showHoverEvents = showEvents === true || showEvents === 'hover'

  const { classes } = useStyles()

  const { isSmallOrSmaller } = useDimensions()

  invariant(!(media && url), 'Cannot specify both media and url')

  const hoverEvents = useRef<AudioEvent[]>([])

  /** Wire up the audio element to the player  */
  const [mediaRef, setMediaRef] = useState<HTMLMediaElement | null>(null)

  const { peaks, loading: loadingPeaks } = usePeaks({ media: isAudio(media) ? media : undefined, url, renderPeaks })

  const { audioProps, seek, togglePlay, playing, duration, position } = useAudioPlayer({
    el: mediaRef,
    name: media?.name ?? 'Unknown',

    autoStop,
    onPlay,
    onPause,
  })
  const handleToggle = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      /**
       * To allow embed in containers that react to clicks, e.g., cards
       * But the audio engine needs to be initialized first.
       */
      ev.preventDefault()
      ev.stopPropagation()
      togglePlay()
    },
    [togglePlay]
  )

  /** AudioTransport control */
  useAudioTransportControl(mediaRef)

  const { transport } = useTransport({ events })
  const [pos, setPos] = useState(0)
  const mediaUrl = media?.storageUrls?.url
  const loading = loadingPeaks

  /** Calculate pixelsPerSecond on every render. Otherwise we need to  */

  const containerSize = useContainerSize(ref.current)
  const width = containerSize?.width ?? 0
  const pixelsPerSecond = useMemo(() => {
    const val = width / duration
    return Number.isNaN(val) ? 1 : val
  }, [duration, width])

  const [popupEl, setPopupEl] = useState<VirtualElement | null>(null)

  const handleSeek = useCallback(
    (pos: AudioPosition) => {
      seek(pos.seconds)
      setPos(pos.seconds / duration)
    },
    [duration, seek]
  )
  const {
    position: audioPosition,
    pagePosition,
    timelineRef: containerRef,
  } = useTimelineInteraction({
    duration: mediaRef?.duration,
    onSeek: handleSeek,
  })

  useHotkey({ hotkey: 'Space', handler: togglePlay, disabled: !keyboardControls, el: ref.current })

  const debounceEl = useDelayedInvoke(50)

  /** If there's an active event, show it in the popup */
  useEffect(() => {
    setPopupEl(null)
    debounceEl(() => {
      if (!activeEvent || !showHoverEvents) {
        return
      }
      const pageX = containerRef.current?.getBoundingClientRect()?.left ?? 0
      const pageY = containerRef.current?.getBoundingClientRect()?.top ?? 0

      const x = activeEvent.start * pixelsPerSecond + pageX
      const y = pageY + 8

      setPopupEl({
        getBoundingClientRect: () => ({
          top: y,
          right: x,
          bottom: y,
          left: x,
          width: 0,
          height: 0,
          x,
          y,
          toJSON: () => '',
        }),
      })
      hoverEvents.current = [activeEvent]
    })
  }, [activeEvent, containerRef, debounceEl, pixelsPerSecond, showHoverEvents])

  useEffect(() => {
    setPopupEl(null)
    debounceEl(() => {
      if (!pagePosition || !showHoverEvents) {
        return
      }
      setPopupEl(trackMouse(pagePosition))
      hoverEvents.current = (events ?? []).filter(EventFilters.contains(pagePosition.seconds))
    })
  }, [debounceEl, events, pagePosition, showHoverEvents])

  /**
   * Refresh position every 100ms when running.
   * @note useAnimationFrame was used previously but was too expensive
   */

  useEffect(() => {
    if (!playing) {
      return
    }
    const id = setInterval(() => {
      const pos = duration ? transport.seconds / duration : 0
      setPos(pos)
    }, 100)
    return () => {
      clearInterval(id)
    }
  }, [duration, media?.name, playing, transport])

  const fabSize = isSmallOrSmaller ? 'small' : 'medium'

  const contentUrl = url ?? mediaUrl
  const hasContent = Boolean(contentUrl)

  return (
    <Box ref={ref} className={classNames(className, classes.root)} {...restProps}>
      {timeline ? (
        <Timeline
          duration={duration}
          events={showEvents === true && timeline !== 'time' ? events : undefined}
          pixelsPerSecond={pixelsPerSecond}
        />
      ) : null}
      {/* <ActiveRegion className={classes.rangeOverlay} region={activeRegion} duration={duration} /> */}
      <Box className={classNames(className, classes.waveform)}>
        <audio ref={setMediaRef} src={contentUrl} controls={false} {...audioProps} />
        {/* {!peaks ? <LinearProgress sx={{ width: '100%' }} /> : null} */}
        {hasContent ? (
          <WaveCanvas className={classes.waveContainer} ref={containerRef} peaks={peaks} position={pos} badge={badge} />
        ) : (
          <LinearProgress sx={{ width: '100%' }} />
        )}
        <Zoom in={true} timeout={200}>
          {loading ? (
            <Fab className={classNames(classes.fab)} color="secondary" size={fabSize}>
              <CircularProgress />
            </Fab>
          ) : (
            <Fab
              className={classNames(classes.fab)}
              color="secondary"
              disabled={loadingPeaks}
              onClick={handleToggle}
              size={fabSize}
            >
              {!playing ? <Play /> : <Stop /* color={recording ? 'error' : 'inherit'} */ />}
            </Fab>
          )}
        </Zoom>
      </Box>
      {isPersisted(media) && showCommentStream ? (
        <CommentStream
          audioPosition={position}
          playing={playing}
          parent={media}
          height={24}
          pixelsPerSecond={pixelsPerSecond}
          duration={duration}
        />
      ) : null}
      {/* {showRegion ? <WaveRegion region={activeRegion} onDelete={handleRegionDelete} onChange={handleRegionEdit} /> : null} */}
      {/* Popup */}
      <Popup open={Boolean(popupEl)} anchorEl={popupEl} placement="right-end">
        <Paper
          sx={{
            px: 1,
            paddingBottom: 1,
            borderRadius: 0,
            borderLeft: theme => `2px solid ${theme.palette.secondary.main}`,
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="caption" display={'flex'}>
            {audioPosition?.label}
          </Typography>
          {hoverEvents.current.map((event, idx) => (
            <React.Fragment key={idx}>
              {idx > 0 ? <Divider /> : null}
              <Event event={event} />
            </React.Fragment>
          ))}
        </Paper>
      </Popup>
    </Box>
  )
}

export default Waveform
