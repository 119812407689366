import { Box, Button, Typography, type SxProps, type Theme } from '@mui/material'
import { Add } from '@tunasong/icons'
import { EntityFilters, hasPublicAccess } from '@tunasong/models'
import { getAcceptedMimeTypes, usePlugins } from '@tunasong/plugin-lib'
import { useEntities, useEntityGraph } from '@tunasong/redux'
import { isFolderContainer } from '@tunasong/schemas'
import { useSearch } from '@tunasong/search'
import { ContentContainer, DndList, DropZone, EntityGrid, useCurrentUser, useNavigateToEntity } from '@tunasong/ui-lib'
import { useMemo, type FC, type MouseEvent } from 'react'
import Recent from './recent-grid.js'

const headerSx: SxProps<Theme> = {
  m: 0,
  mt: 4,
  mb: 2,
  textAlign: 'center',
  color: theme => theme.vars.palette.text.secondary,
}

export interface RootMainProps {}

/** The  */
export const RootMain: FC<RootMainProps> = props => {
  const {} = props
  const { userId } = useCurrentUser()
  const navigateToEntity = useNavigateToEntity()

  const { results: sharedSearchResults, searching } = useSearch({ queryType: 'shared', query: userId, size: 500 })

  /** Filter out results that has a parentId as well */
  const sharedResultIds = new Set([...sharedSearchResults.map(r => r.id)])

  const { entities: ownedEntities, isLoading: isLoadingEntities } = useEntities({
    root: true,
    filter: EntityFilters.folder,
    pollingInterval: 30000,
  })
  const ownedResultIds = new Set([...ownedEntities.map(r => r.id)])

  const sharedResults = sharedSearchResults
    .filter(r => (r.parentId ? !sharedResultIds.has(r.parentId) : true))
    .filter(r => !ownedResultIds.has(r.id))

  const allEntities = [...ownedEntities, ...sharedResults]

  const spaces = allEntities
    // Only show top-level entities
    .filter(e => !Boolean(e.parentId) || e.parentId === 'ROOT')
    .filter(isFolderContainer)
    // Filter out spaces that are public. We may want to surface those in a different way
    .filter(e => !hasPublicAccess(e.acls))
    .sort((a, b) => (a.name ?? '').localeCompare(b?.name ?? ''))
  const spacesIds = new Set([...spaces.map(r => r.id)])

  const graph = useEntityGraph()

  const addSpace = async (ev: MouseEvent) => {
    ev.preventDefault()

    const space = await graph
      .createEntity({ parent: null, entity: { type: 'folder', name: 'New Space', tags: ['space'] } })
      .unwrap()
    if (space) {
      navigateToEntity(space)
    }
  }
  const busyCreating = graph.createEntityResult.isLoading

  const inbox = ownedEntities.filter(r => !spacesIds.has(r.id))

  const plugins = usePlugins('all')
  const mimeTypes = useMemo(() => getAcceptedMimeTypes(plugins), [plugins])

  return (
    <ContentContainer sx={{ mb: 7 }}>
      <DropZone parentId={null} accept={mimeTypes} uploadOnClick showClickZone>
        <Typography sx={headerSx} variant="h2">
          Activity
        </Typography>
        <Recent />
        <Box sx={{ position: 'relative' }}>
          <Typography sx={{ ...headerSx, mb: 4, mt: 0 }} variant="h2">
            Spaces
          </Typography>
          <Button
            disabled={busyCreating}
            onClick={addSpace}
            startIcon={<Add />}
            sx={{ position: 'absolute', right: 0, top: 0 }}
            color="primary"
          >
            New Space
          </Button>
        </Box>
        <EntityGrid
          skeletonItems={9}
          entities={spaces}
          loading={isLoadingEntities || searching}
          onOpen={navigateToEntity}
        />

        <Typography sx={{ ...headerSx, mb: 0 }} variant="h2">
          Inbox
        </Typography>
        {/* <EntityGrid entities={inbox} loading={isLoadingEntities || searching} onOpen={navigateToEntity} /> */}
        <DndList size="large" isLoading={isLoadingEntities || searching} entities={inbox} optionsKey={'root'} />
      </DropZone>
    </ContentContainer>
  )
}

export default RootMain
