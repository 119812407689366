import { shortUuid, type Maturity } from '@tunasong/models'
import { maturityFilter, type TunaEditor, type TunaEditorConfig, type TunaPlugin } from '@tunasong/plugin-lib'
import { useThunkDispatch, type RootState } from '@tunasong/redux'
import { isCoreElement, type CoreElement, type EntityOrElement } from '@tunasong/schemas'
import { useUserConfig } from '@tunasong/ui-lib'
import { createPlateEditor, createPlatePlugin, withPlate, type PlatePlugin } from '@udecode/plate-core/react'
import { useEffect, useMemo, useState } from 'react'
import { useStore } from 'react-redux'

/** Create the (raw) editor. If you manage this you need to handle the root properties (e.g., id) separately. In the normal case, use `useCreateEditor` instead */
export const createTunaEditor = <T extends TunaEditor = TunaEditor>(
  id: string,
  plugins: TunaPlugin[],
  config: TunaEditorConfig,
  maturity: Maturity
) => {
  const ed = createPlateEditor<CoreElement[]>()

  /** corePlugins are included with Plate, filtered by maturity */
  const filteredPlugins = plugins.filter(maturityFilter(maturity))

  /** @todo do this in the plugins instead? */
  const preparePlugin = (p: TunaPlugin) => {
    const subPlugins = p.plugins?.map(p => createPlatePlugin(p as unknown as PlatePlugin)) ?? []
    return createPlatePlugin({ ...p, plugins: subPlugins } as unknown as PlatePlugin)
  }
  const platePlugins = filteredPlugins.map(preparePlugin)

  const editor = withPlate(ed, {
    id,
    plugins: platePlugins,
  })

  editor.config = config

  return editor as unknown as T
}

// Create a new editor. It will re-create if rootElement.id or plugins change.
export const useCreateEditor = (element: EntityOrElement | null, plugins: TunaPlugin[] | null) => {
  const dispatch = useThunkDispatch()
  const { getState } = useStore<RootState>()

  /** We allow only once creation per rootElement */
  const [rootElement, setRootElement] = useState<EntityOrElement | null>(element)

  useEffect(() => {
    if (!rootElement && element) {
      setRootElement(element)
    }
  }, [element, rootElement])

  const [maturity] = useUserConfig('featureMaturity')

  const editor = useMemo(() => {
    if (!plugins || !rootElement) {
      return null
    }
    const id = shortUuid()
    const editor = createTunaEditor(id, plugins, { dispatch, getState }, maturity)

    if (isCoreElement(rootElement)) {
      editor.rootElement = rootElement
      editor.children = rootElement.children ?? []
    }
    return editor
  }, [dispatch, getState, maturity, plugins, rootElement])

  return editor
}
