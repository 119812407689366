import SmartButton from '@mui/icons-material/SmartButton'
import { Box, Divider, ToggleButton, Typography, useMediaQuery, useTheme, type BoxProps } from '@mui/material'
import { EntityNotes } from '@tunasong/editor'
import {
  Activities,
  Context,
  Debug,
  Share as Graph,
  Guitar,
  Microphone,
  Note as Notes,
  Pool,
  Settings,
  Suggestion,
  type IconProps,
} from '@tunasong/icons'
import { isCollabEntity, isPersistedEntity, logger, matchAny } from '@tunasong/models'
import { ChatBadge, useChat } from '@tunasong/plugin-chat/ui'
import { useEntityUpdate } from '@tunasong/redux'
import { isChat, isPage, isSong, type Entity, type Persisted } from '@tunasong/schemas'
import { HBox, VBox, isDevelopment, useElementRef, useSidebar, type SidebarHandler } from '@tunasong/ui-lib'
import { useCallback, useEffect, useMemo, useRef, type FC } from 'react'
import invariant from 'tiny-invariant'
import { SidebarActivities } from './sidebar-activities.js'
import { SidebarChat } from './sidebar-chat.js'
import { SidebarContext } from './sidebar-context.js'
import { SidebarDebug } from './sidebar-debug.js'
import { SidebarEvents } from './sidebar-events.js'
import { SidebarGraph } from './sidebar-graph.js'
import { SidebarGuitar } from './sidebar-guitar.js'
import { SidebarPool } from './sidebar-pool.js'
import { SidebarRecord } from './sidebar-record.js'
import { SidebarSettings } from './sidebar-settings.js'
import { SidebarSuggestion } from './sidebar-suggestion.js'

export interface SidebarProps extends BoxProps {
  className?: string
  entity?: Persisted<Entity>
}

const Sidebar: FC<SidebarProps> = props => {
  const { entity, sx, ...restProps } = props

  const { activeTool, setActiveTool } = useSidebar()

  const { activeComments, isLoading: isChatLoading } = useChat({ element: entity })
  /** If we have a ?ref in the URL, we open the chat if a comment has the same ID */
  const ref = useElementRef()
  useEffect(() => {
    const hasRefComment = ref && activeComments.find(c => c.id === ref)
    if (hasRefComment) {
      /** Chat is tool #1 */
      setActiveTool('Chat')
    }
  }, [activeComments, ref, setActiveTool])

  /** If activeComments change, we automatically open the chat tool */
  const prevActiveComments = useRef<number>(0)
  useEffect(() => {
    if (prevActiveComments.current > 0 && prevActiveComments.current < activeComments.length && !isChatLoading) {
      logger.debug('Setting active', prevActiveComments, activeComments)
      setActiveTool('Chat')
    }
    prevActiveComments.current = activeComments.length
  }, [activeComments, isChatLoading, setActiveTool])

  const handleUpdate = useEntityUpdate({ debounceDelay: 3 * 1000 })
  const handleChange = useMemo(
    () => (partial: Partial<Entity>) => {
      invariant(isPersistedEntity(entity), 'Entity to be updated must be an element')
      handleUpdate(entity.id, partial)
    },
    [entity, handleUpdate]
  )

  const numComments = activeComments.length
  const ChatIcon = useMemo(
    () => (props: IconProps) => <ChatBadge numComments={numComments} {...props} />,
    [numComments]
  )

  const tools: SidebarHandler[] = useMemo(
    () =>
      [
        {
          name: `Settings`,
          Icon: Settings,
          Component: SidebarSettings,
          disabled: () => false,
        },
        {
          name: 'Chat',
          Icon: ChatIcon,
          Component: SidebarChat,
          disabled: () => isChat(entity),
        },
        {
          name: 'Activities',
          description: 'Summary of recent activities',
          Icon: Activities,
          Component: SidebarActivities,
          disabled: () => false,
        },
        {
          name: 'Notes',
          description:
            'Notes are personal to you. Write down your e.g., performance notes here. Notes will be shown in the performance view.',
          Icon: Notes,
          Component: EntityNotes,
          disabled: () => false,
        },
        {
          name: 'Event Commands',
          description:
            'Commands to run for different events. For example, you can run a command when a the page is opened.',
          Icon: SmartButton,
          Component: SidebarEvents,
          disabled: () => false,
        },
        {
          name: 'Graph',
          description: 'Show the outgoing and incoming links in the content graph.',
          Icon: Graph,
          Component: SidebarGraph,
          disabled: (entity: Entity) => !entity,
        },
        {
          name: 'Pool',
          description: 'The Pool contains media that belongs to this item. Select media to insert it into the page.',
          Icon: Pool,
          Component: SidebarPool,
          disabled: (entity: Entity) => !matchAny(isSong, isPage)(entity),
        },
        {
          name: 'Context',
          description: 'The context shows the musical context. If you are in an editor your position will matter.',
          Icon: Context,
          Component: SidebarContext,
          disabled: (entity: Entity) => !isSong(entity),
        },
        {
          name: 'Suggestions',
          description: 'Suggestions for chords, notes or scales based on your current context',
          Icon: Suggestion,
          Component: SidebarSuggestion,
          disabled: (entity: Entity) => !isSong(entity),
        },

        {
          name: 'Record',
          description: 'Record your performance',
          Icon: Microphone,
          Component: SidebarRecord,
          disabled: (entity: Entity) => !isSong(entity),
        },
        {
          name: 'Guitar',
          description: 'Guitar tools',
          Icon: Guitar,
          Component: SidebarGuitar,
          disabled: (entity: Entity) => !isSong(entity),
        },
        isDevelopment()
          ? {
              name: 'Debug',
              description: 'Debug information',
              Icon: Debug,
              Component: SidebarDebug,
              disabled: (entity: Entity) => !isCollabEntity(entity),
            }
          : null,
      ].filter(Boolean) as SidebarHandler[],
    [ChatIcon, entity]
  )

  /** When the entity is not set we collapse the toolbar */
  useEffect(() => {
    if (!entity) {
      setActiveTool(null)
      return
    }
    /** If there is an active tool, and it's disabled, we also collapse */
    if (activeTool && tools.find(t => t.name === activeTool)?.disabled(entity)) {
      setActiveTool(null)
    }
  }, [activeTool, entity, setActiveTool, tools])

  const toggleActive = useCallback(
    (num: number) => () => {
      if (tools.findIndex(tool => tool.name === activeTool) === num) {
        setActiveTool(null)
      } else {
        setActiveTool(tools[num].name)
      }
    },
    [activeTool, setActiveTool, tools]
  )

  const theme = useTheme()
  const xl = useMediaQuery(theme.breakpoints.up('xl'))
  const tiny = useMediaQuery(theme.breakpoints.down('sm'))

  const handler = activeTool !== null ? tools.find(t => t.name === activeTool) : null
  const ActiveComponent = handler ? handler.Component : null
  const showSidebarIcons = !tiny || (tiny && ActiveComponent)

  // 100vw is a hack to make the sidebar full width on mobile
  const containerWidth = tiny && activeTool ? '100vw' : 'auto'

  const toolWidth = activeTool ? (xl ? 450 : 350) : 0

  return (
    <HBox
      sx={{
        ...sx,
        width: containerWidth,
        backgroundColor: theme.vars.palette.layers,
        transition: theme.transitions.create('width'),
        zIndex: 1,
        borderLeft: theme => `solid 1px ${theme.vars.palette.divider}`,
        // reset coordinate system with a transform
        transform: 'translateZ(0)',
      }}
      {...restProps}
    >
      <VBox
        sx={{
          /** Slide in the sidebar */
          transition: 'all 0.25s',
          width: toolWidth,
          opacity: activeTool ? 1 : 0,
          pb: activeTool ? 2 : 0,
          px: activeTool ? 1 : 0,
          overflow: 'auto',
          flex: 1,
        }}
      >
        {ActiveComponent && entity && (
          <>
            <Box>
              <Divider>
                <Typography variant="h4" gutterBottom>
                  {handler?.name}
                </Typography>
              </Divider>
            </Box>
            {handler?.description ? (
              <>
                <Typography variant="caption" gutterBottom>
                  {handler?.description}
                </Typography>
              </>
            ) : null}
            <ActiveComponent entity={entity} onChange={handleChange} />
          </>
        )}
      </VBox>
      {showSidebarIcons ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          {tools.map(({ name, disabled, Icon }, idx) => (
            <ToggleButton
              key={idx}
              sx={{
                border: 'none !important',
                borderRadius: 0,
                p: 2,
                color: theme => theme.vars.palette.text.secondary,
              }}
              disabled={!entity || disabled(entity)}
              title={`${name}`}
              value={name}
              selected={activeTool === tools[idx].name}
              onClick={toggleActive(idx)}
            >
              <Icon fontSize={'medium'} color={activeTool === tools[idx].name ? 'secondary' : 'inherit'} />
            </ToggleButton>
          ))}
        </Box>
      ) : null}
    </HBox>
  )
}

export default Sidebar
